import React from "react"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"
import Login from "../components/account/login-panel"

const LoginPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={"Login"} location={location} />
      <Login location={location} />
    </Layout>
  )
}

export default LoginPage
