import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { Formik, Form } from "formik"

import AuthContext from "../../context/auth/auth-context"
import CartContext from "../../context/cart/cart-context"

import BlurLoader from "../generic/loaders/blur-loader"
import LinkWrapper from "../../lib/link-wrapper"

import AccountsFormInputField from "./accounts-form-input-field"
import ResponsiveButton from "./responsive-button"

// After logging in, never redirect back to these pages
const redirectBlacklist = ["/autres/", "/app/"]

// ================
// 	  COMPONENT
// ================

const Login = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [apiError, setApiError] = useState(false)
  const { performLogin, loggedInState } = useContext(AuthContext)
  const { getCart } = useContext(CartContext)

  // On submit of the fields
  const submitHandler = ({ email, password }) => {
    setApiError("")
    performLogin(email, password, setLoading, setApiError, getCart)
  }

  // When a log in happens
  useEffect(() => {
    if (loggedInState === true) {
      console.log("Logged in")

      // We check if a fromUrl was passed from wherever the user clicked on "Log in"
      // But if the fromUrl contains one of the redirectBlacklist URL's, we don't use it
      if (
        location.state &&
        location.state.fromUrl &&
        !redirectBlacklist.some((badurl) =>
          location.state.fromUrl.includes(badurl)
        )
      ) {
        navigate(`${location.state.fromUrl}`, {
          replace: true,
          state: { showNotice: "You have been logged in" },
        })
      } else {
        navigate(`/my-account/`, {
          replace: true,
          state: { showNotice: "You have been logged in" },
        })
      }
    }
  }, [loggedInState])

  return (
    <LoginPanelContainer>
      <BlurLoader
        loading={loading || loggedInState === null}
        message="Logging in"
      >
        <LoginFormSectionStyling>
          <h2>Login</h2>
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={validate}
            onSubmit={submitHandler}
          >
            {({ isSubmitting, errors, touched }) => (
              <>
                <Form>
                  <AccountsFormInputField
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="jane@doe.com"
                    errors={errors}
                    touched={touched}
                  />
                  <AccountsFormInputField
                    label="Password"
                    type={hidePassword ? "password" : "text"}
                    hidePassword={hidePassword}
                    autoComplete="new-password"
                    name="password"
                    errors={errors}
                    touched={touched}
                    toggle={() => {
                      setHidePassword((prevState) => !prevState)
                    }}
                  />
                  {apiError && <ErrorStyle>{apiError}</ErrorStyle>}
                  <StyledButton>
                    <button disabled={loading}>LOGIN</button>
                  </StyledButton>
                </Form>
                <Extra>
                  <LinkWrapper to="/reset/">Forgot Password?</LinkWrapper>
                </Extra>
                <Extra style={{ marginTop: "50px" }}>
                  <h5>New here?</h5>
                  <StyledLinkWrapper className="register" to="/register/">
                    <button disabled={loading}>Register</button>
                  </StyledLinkWrapper>
                </Extra>
              </>
            )}
          </Formik>
        </LoginFormSectionStyling>
      </BlurLoader>
    </LoginPanelContainer>
  )
}

// ===============
//     STYLES
// ===============
const ErrorStyle = styled.div`
  text-align: center;
  color: red;
`
const LoginPanelContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .show-on {
    margin-top: 20px;
  }
`
const LoginFormSectionStyling = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  .half-width-input input {
    display: block;
  }
  label {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
  }
  .half-width-input {
    max-width: 400px;
    width: 100%;
    display: inline-block;
  }
  & form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .half-width-input {
      width: 48%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .half-width-input {
      width: 100%;
    }
    & form > div {
      width: 100%;
    }
    & form > div button {
      width: 100%;
    }
  }
`
const StyledButton = styled.div`
  margin: 20px 0;
  width: 100%;
  button {
    width: 100%;
  }

  &.register {
    button:not(:hover) {
      background-color: #707070;
      border-color: #707070;
    }
  }
`

const StyledLinkWrapper = styled(LinkWrapper)`
  margin: 20px 0;
  width: 100%;
  button {
    margin-top: 10px;
    width: 100%;
  }

  &.register {
    text-decoration: none;
    button:not(:hover) {
      background-color: #707070;
      border-color: #707070;
    }
  }
`
const Extra = styled.div`
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.black1};
  text-align: center;
  a {
    color: ${({ theme }) => theme.colors.black1};
    text-decoration: underline;
  }
`
// ===============
// 	   HELPERS
// ===============
const validate = ({ email, password }) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!email, "email", "Email is required")
  addIf(!/\S+@\S+\.\S+/.test(email), "email", "Email is invalid")
  addIf(!password, "password", "Password is required")
  addIf(
    password.length < 6,
    "password",
    "Password should be at least 6 characters"
  )

  return errors
}

export default Login
