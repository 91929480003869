import React from "react"
import styled from "styled-components"

import LinkWrapper from "../../lib/link-wrapper"
import ChevronRight from "../../images/icons/chevron-right"

// ======================
// 	     COMPONENT
// ======================
const ResponsiveButton = ({ desktop }) => {
  return (
    <ResponsiveStyle desktop={desktop} className="show-on">
      <Button to="/">
        <ChevronRight hue="white" shade="000" size="lg" />
        <div>HOMEPAGE</div>
      </Button>
    </ResponsiveStyle>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const ResponsiveStyle = styled.div`
  display: ${({ desktop }) => (desktop ? "block" : "none")};
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: ${({ desktop }) => (desktop ? "none" : "block")};
  }
`
const Button = styled(LinkWrapper)`
  border-radius: 3px;
  border: 1px solid white;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(-3px);
  }
  span.icon {
    border-right: 1px solid white;
  }
  div {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    padding: 0 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    div {
      font-size: 20px;
    }
  }
`

export default ResponsiveButton
